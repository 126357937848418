import styled from 'styled-components/macro';

const PillsIcon = ({className, width = 13, height = 19}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M1.21582 0.0429688H10.9971V3.01216H1.21582V0.0429688Z" fill="#0A6629" />
    <path
      d="M4.52112 15.4225V7.50143H12.2104V6.08332C12.2104 5.40726 11.9915 4.75531 11.6088 4.23047H0.601797C0.21913 4.75531 0 5.40726 0 6.08332V18.4336H12.2104V15.4225H4.52112Z"
      fill="#0A6629"
    />
    <path d="M5.70264 8.74805H12.211V14.1769H5.70264V8.74805Z" fill="#0A6629" />
  </svg>
);

export default styled(PillsIcon)``;

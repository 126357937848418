import styled from 'styled-components/macro';

const MealsIcon = ({className, width = 32, height = 34}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M13.1416 3.05469H11.2998V8.45993H13.1416V3.05469Z" fill="#0A6629" />
    <path d="M20.5088 3.05469H18.667V8.45993H20.5088V3.05469Z" fill="#0A6629" />
    <path d="M16.8257 0.724609H14.9839V6.44783H16.8257V0.724609Z" fill="#0A6629" />
    <path
      d="M25.3065 19.1532C23.0136 16.7783 20.0307 15.3784 16.8262 15.1527V12.894H14.9843V15.1527C11.7798 15.3784 8.79678 16.7783 6.50388 19.1533C4.21103 21.5282 2.85943 24.6179 2.6416 27.9371H29.1689C28.9511 24.6179 27.5995 21.5281 25.3065 19.1532Z"
      fill="#0A6629"
    />
    <path d="M31.6216 29.8453H0.1875V33.2708H31.6216V29.8453Z" fill="#0A6629" />
  </svg>
);

export default styled(MealsIcon)``;

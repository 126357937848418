import styled from 'styled-components/macro';

const BoxIcon = ({className, width = 32, height = 36}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.2676 17.0561L1.34206 10.3134C1.14256 10.2168 0.921875 10.3853 0.921875 10.6343V28.6603C0.921875 29.1158 1.16303 29.5255 1.53084 29.6946L15.0377 35.9076C15.2365 35.9991 15.4529 35.8307 15.4529 35.5844V17.377C15.4529 17.2369 15.3798 17.1105 15.2676 17.0561Z"
      fill="#0A6629"
    />
    <path
      d="M16.5381 15.1405L29.9983 8.62306C30.2403 8.50595 30.2468 8.11437 30.0089 7.98675L16.8303 0.914428C16.5706 0.775106 16.2707 0.775106 16.011 0.914428L2.83244 7.98675C2.59455 8.11437 2.60103 8.50595 2.84297 8.62306L16.3032 15.1405C16.3783 15.1769 16.463 15.1769 16.5381 15.1405Z"
      fill="#0A6629"
    />
    <path
      d="M17.3911 17.377V35.5844C17.3911 35.8307 17.6074 35.9991 17.8063 35.9076L31.3131 29.6946C31.6809 29.5254 31.9221 29.1158 31.9221 28.6603V10.6343C31.9221 10.3853 31.7014 10.2168 31.5019 10.3134L17.5764 17.0561C17.4641 17.1105 17.3911 17.2369 17.3911 17.377Z"
      fill="#0A6629"
    />
  </svg>
);

export default styled(BoxIcon)``;
